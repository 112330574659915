import Head from "next/head";
import { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Footer, Source, Security, AffiliateInfo } from "../components";
export default function New() {
	const [redirect, setRedirect] = useState(false);
	const [learnOpen, setLearnOpen] = useState(false);
	const [affiliateOpen, setAffiliateOpen] = useState(false);
	const [affiliateCode, setAffiliateCode] = useState("");
	const [status, setStatus] = useState("");
	const router = useRouter();
	const query = router.query;
	const affiliateQuery = router.query.code || router.query.affiliate;
	useEffect(() => {
		affiliateQuery ? setAffiliateCode(affiliateQuery) : "";
	}, [affiliateQuery]);

	useEffect(async () => {
		const data = await fetch(`${process.env.NEXT_PUBLIC_WEBSITE}/api/auth/@me`, { credentials: "include" }).then((res) => res.json());
		if (data && !data.error && data.access) location.href = query.redirect || "/landing";
		else if (data && !data.error && !data.access) router.push("/unauthorized");
	}, [router]);

	async function startRedirect(redirect) {
		setRedirect(true);
		const res = await fetch(`${process.env.NEXT_PUBLIC_WEBSITE}/api/auth/loginurl?redirect=${redirect || "/landing"}`);
		const json = await res.json();
		router.push(json.url);
	}

	async function submitAffiliate() {
		if (affiliateCode.length == 0) return;
		setStatus("processing");
		const json = await fetch(`${process.env.NEXT_PUBLIC_WEBSITE}/api/auth/affiliate`, { method: "POST", credentials: "include", body: JSON.stringify({ code: affiliateCode }) }).then((res) =>
			res.json()
		);
		if (json.msg == "Sign In With Google First") startRedirect(`/unauthorized?beta=${affiliateCode}`);
		setStatus(json.msg);
		setAffiliateCode("");
		if (!json.error) setData(await fetch(`${process.env.NEXT_PUBLIC_WEBSITE}/api/auth/@me`, { credentials: "include" }).then((res) => res.json()));
	}
	return (
		<div className="">
			<Head>
				<title>John Jay High School WCSD Map</title>
				<link href="/main.css" rel="stylesheet" type="text/css" />
				<Source />
			</Head>
			<div className="py-20 dark:bg-gray-900">
				<div className="mx-auto my-10 flex max-w-sm rounded-lg bg-white shadow-lg dark:bg-gray-800 lg:max-w-4xl">
					<div className="hidden bg-cover lg:block lg:w-1/2" style={{ backgroundImage: "url('https://cdn.discordapp.com/attachments/769275863786455057/913234503617626182/Tempfile.png')" }}></div>
					<div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
						<h2 className="text-center text-2xl font-semibold text-gray-700 dark:text-white">John Jay High School (WCSD) Map</h2>
						<p className="text-md text-center text-gray-600 dark:text-gray-200">A collaboration between John Jay Robotics & Administration. Programmed by Anish A ('24) and Robert Z ('22). </p>

						<div className="mt-4 flex items-center justify-between">
							<span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
							<a className="text-center text-xs uppercase text-gray-500 dark:text-gray-400">WCSD Teacher Login</a>
							<span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
						</div>
						{!redirect && (
							<>
								<a
									onClick={() => {
										startRedirect(query.redirect);
									}}
									className="mt-4 flex items-center justify-center rounded-lg border text-gray-600 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
								>
									<div className="px-4 py-2">
										<svg className="h-6 w-6" viewBox="0 0 40 40">
											<path
												d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
												fill="#FFC107"
											/>
											<path
												d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z"
												fill="#FF3D00"
											/>
											<path
												d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z"
												fill="#4CAF50"
											/>
											<path
												d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
												fill="#1976D2"
											/>
										</svg>
									</div>
									<span className="w-5/6 px-4 py-3 text-center font-bold">Sign in with Google</span>
								</a>
							</>
						)}
						{redirect && (
							<>
								<a className="mt-4 flex cursor-wait items-center justify-center rounded-lg border text-gray-600 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600">
									<span className="inline-flex w-5/6 justify-center px-4 py-3 text-center font-bold">
										<svg className="h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
											<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
									</span>
								</a>
							</>
						)}
						{query.redirect && query.redirect.includes("/unauthorized?beta=") && (
							<>
								<div className="mt-2 rounded bg-blue-300 px-4 py-3 text-blue-900 shadow-md" role="alert">
									<div className="flex">
										<div className="py-1">
											<svg className="mr-4 h-6 w-6 fill-current text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
												<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
											</svg>
										</div>
										<div>
											<p className="font-bold">Beta Access</p>
											<p className="text-sm">Once you login, your beta code will be applied!</p>
										</div>
									</div>
								</div>
							</>
						)}
						{query.redirect && query.redirect.includes("/beta") && (
							<>
								<div className="mt-2 rounded bg-blue-300 px-4 py-3 text-blue-900 shadow-md" role="alert">
									<div className="flex">
										<div className="py-1">
											<svg className="mr-4 h-6 w-6 fill-current text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
												<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
											</svg>
										</div>
										<div>
											<p className="font-bold">MAP-gic?</p>
											<p className="text-sm">Once you login, your code will be activated!</p>
										</div>
									</div>
								</div>
							</>
						)}
						<div className="mt-4 flex items-center justify-between">
							<span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
							<a className="text-center text-xs uppercase text-gray-500 dark:text-gray-400">Additional Links</a>
							<span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
						</div>

						<div className="mt-4">
							<button
								onClick={() => {
									setLearnOpen(true);
								}}
								className="mt-3 block w-full items-center justify-center rounded-md border border-gray-700 bg-gray-700 px-4 py-2 text-center text-gray-200 hover:bg-gray-600"
							>
								Learn More
							</button>
							<a
								href="//jjscioly.club"
								target="_BLANK"
								rel="noreferrer"
								className="mt-3 flex items-center justify-center rounded-md border border-gray-700 bg-gray-700 px-4 py-2 text-center text-gray-200 hover:bg-gray-600"
							>
								Main Website
							</a>
						</div>
					</div>
				</div>
			</div>
			<Security isOpen={learnOpen} setIsOpen={setLearnOpen} />
			<AffiliateInfo isOpen={affiliateOpen} setIsOpen={setAffiliateOpen} />
			<Footer />
		</div>
	);
}
